.testimonials{
    display: flex;
    gap: 1rem;
    padding: 0,2rem;
    padding-top: 2rem;
}
.left-t{
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
    padding-left: 2rem;
}
.left-t>:nth-child(1){
    color: orange;
}
.left-t>:nth-child(2),
.left-t>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}
.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}
.right-t{
    flex: 1;
    position: relative;
}
.right-t>img{
   position: absolute;
   width: 17rem;
   height: 20rem;
   object-fit: cover;
   right: 8rem; 
   top: 2rem;
}
.right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orangered;
    background-color: transparent;
    right: 9rem;
    top: 0.9rem;
}
.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    right: 7rem;
    top: 4rem;
    background:orange ;
}
.arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom:1rem;
    left: 3rem;

}
.arrows>img{
    width: 1.9rem;
    cursor: pointer;
}

@media screen and(max-width: 768px){
    .testimonials{
        flex-direction: column;
    }
    .left-t>:nth-child(2),.left-t>:nth-child(3){
        font-size: xx-large;
    }
    .right-t{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .right-t>div{
        position:relative;
        display: none;
    }
    .right-t>img{
       top: 0;
       right: 0;
       position: relative;

    }
  .right-t>:last-child{
    display: block ;
    bottom: 0;
    left: 0;
  }    

}