.App{
  background-color:dimgray;
}
.strock-text{
  color:transparent ;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color:white;
}
.btn{
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  align-items: center;
  justify-content: center;
}
.btn:hover{
  cursor: pointer;
}
.blur{
  background: rgba(253,120,43,0.69 );
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  /* z-index: -9;  */
}