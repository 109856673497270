.Join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
    padding-top: 2rem;
}
.left-join{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.left-join>hr{
    position: absolute;
    width: 3.8em;
    border: 2px solid orange;
    border-radius: 20%;
    margin: -10px 0;
}
.right-join{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: lightgray;
}
::placeholder{
    color: lightgray;
}
.btn-j{
    background-color:orange ;
    color: white;
}
@media screen and(max-width: 768px){
    .Join{
        flex-direction: column;
        gap: 1rem;
    }
    .left-join{
        font-size: x-large;
        flex-direction: column;
    }
    .right-join{
        padding: 2rem;
    }
}